import request from '@/utils/request.js';
import { reqMethodEnum } from '@/enumerators/http.js'
const { GET, POST } = reqMethodEnum;

// 管理端-菜单配置 列表
export function getMenus(data) {
  return request({
    url: '/backend/web/system-power/menus',
    method: GET,
    params:data
  });
}

// 管理端-菜单配置 删除
export function PostMenuDelete(data) {
  return request({
    url: '/backend/web/system-power/menu-delete',
    method: POST,
    data
  });
}

// 管理端-菜单配置 编辑详情
export function GettMenuDetail(data) {
  return request({
    url: '/backend/web/system-power/menu-detail',
    method: GET,
    params:data
  });
}

// 管理端-菜单配置 编辑
export function PostMenuUpdate(data) {
  return request({
    url: '/backend/web/system-power/menu-update',
    method: POST,
    data
  });
}

// 管理端-菜单配置 更改状态
export function PostMenuUpdateStatus(data) {
  return request({
    url: '/backend/web/system-power/menu-update-status',
    method: POST,
    data
  });
}

// 删除图标
export function PostDeleteIcon(data) {
  return request({
    url: '',
    method: POST,
    data
  });
}

// 管理端-菜单配置 添加菜单
export function PostMenuCreate(data) {
  return request({
    url: '/backend/web/system-power/menu-create',
    method: POST,
    data
  });
}